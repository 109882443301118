
import { defineComponent, computed } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import router from "@/router";
export default defineComponent({
  name: "notifications-menu",
  components: {},
  methods: {
    async MarkAsRead(item) {
      event?.preventDefault();
      this.notifications.find((x) => x.id == item.id).is_read = true;
      await this.store.dispatch(Actions.MARK_AS_READ, item.id);

      if(item.notifiable_type == 'App\\Models\\Hacktivity'){
        router.push({ name: "feed-view", params: { id: item.notifiable.id } });
      }
    },
    async MarkAllAsRead() {
      event?.preventDefault();
      this.notifications.forEach((x) => (x.is_read = true));
      await this.store.dispatch(Actions.MARK_ALL_AS_READ);
    },
    ViewAll() {
      event?.preventDefault();
      router.push({ name: "notifications"});
    },
  },
  setup() {

    const store = useStore();
    const notifications = computed(() => {
      return store.getters.getCurrentNotifications;
    })
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    var unread_total = computed(() => {
      return store.getters.getCurrentNotificationsCount;
    })

    return {
      getIllustrationsPath,
      unread_total,
      notifications,
      moment,
      store,
      router,
      currentLanguage
    };
  },
});
