
import {computed, defineComponent, onMounted, ref, defineProps, watch} from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
// import MainMenuConfig from "@/core/config/MainMenuConfig";
import {useStore} from "vuex";
import config from '../../core/config/DefaultLayoutConfig';

export default defineComponent({
  name: "kt-menu",
  components: {},
  props: {
    isToggleActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props) {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const user = computed(() => {
      return store.getters.currentUser;
    });
    const MainMenuConfig = ref([]);

    const MainMenuConfigData = (language: string) => {
      return [
        {
          pages: [
            {
              heading: t(`${language}.common.dashboard`),
              route: "/dashboard",
              svgIcon: "media/icons/duotune/art/art002.svg",
              fontIcon: "bi-app-indicator",
            },
          ],
        },
        {
          heading: 'Start here',
          route: "/apps/vpn",
          pages: [
            {
              heading: t(`${language}.left_menu.network_access`),
              route: "/apps/vpn",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-share",
            },
            {
              heading: t(`${language}.left_menu.global_search`),
              route: "/apps/global-search",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-search",
              is_new: true
            }
          ],
        },
        {
          heading: t(`${language}.left_menu.championship`),
          route: "/championship",
          pages: [
            {
              heading: t(`${language}.left_menu.championship_machines`),
              route: "/laboratory/competition-machines",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-joystick",
            },
            {
              heading: t(`${language}.left_menu.leaderboard`),
              route: "/club/leaderboard",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-trophy",
            }
          ],
        },
        {
          heading: t(`${language}.left_menu.club`),
          route: "/apps",
          pages: [
            {
              heading: t(`${language}.left_menu.pathways`),
              route: "/training/learning-paths",
              is_new: true,
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-layer-forward",
            },
            {
              heading: t(`${language}.left_menu.courses`),
              route: "/training/course-catalog",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-camera-reels",
            },
            {
              heading: t(`${language}.left_menu.training_machines`),
              route: "/training/training-machines",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-diagram-3",
            },
            {
              heading: t(`${language}.left_menu.community_feed`),
              route: "/club/feed",
              svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
              fontIcon: "bi-chat-left-quote"
            },
            {
              heading: t(`${language}.left_menu.certifications`),
              route: "/certifications",
              svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
              fontIcon: "bi bi-mortarboard"
            },
            {
              heading: t(`${language}.left_menu.users_lists`),
              route: "/podium",
              svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
              fontIcon: "bi bi-nut"
            }
          ],
        },
        {
          heading: 'Support and Community',
          route: "/apps",
          pages: [
            {
              heading: 'Discord',
              external: true,
              route: "https://discord.gg/qHDU9zsNe2",
              svgIcon: "media/icons/duotune/communication/com006.svg",
              fontIcon: "bi-discord",
            }
          ],
        },
      ]
    }

    const updateMainMenuConfig = (language: string) => {
      //@ts-ignore
      MainMenuConfig.value = MainMenuConfigData(language);
    }

    updateMainMenuConfig(currentLanguage.value);

    watch(() => currentLanguage.value, (newLanguage) => {
      updateMainMenuConfig(newLanguage);
    });

 
    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      user,
      currentLanguage
    };
  },
});
