
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    return {
      loaderType,
      currentLanguage
    };
  },
});
