
import {defineComponent, computed, watch, onBeforeMount} from "vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTNotifications from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
export default defineComponent({
  name: "topbar",
  components: {
    KTQuickLinksMenu,
    KTUserMenu,
    KTNotifications
  },
  setup() {
    const store = useStore();
    onBeforeMount(async () => {
      await store.dispatch(Actions.GET_ALL_NOTIFICATIONS);
    });
    const notifications_count = computed(() => {
      return store.getters.getCurrentNotificationsCount
    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const changeLanguage = (lang: string) => {
      store.commit(Mutations.SET_CHANGE_LANGUAGE, lang);
    };

    return {
      currentUser,
      notifications_count,
      currentLanguage,
      changeLanguage
    };
  },
});
