
import { computed, defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { components } from "@/assets/ts";
import  {useStore} from "vuex";
import {Mutations, Actions} from "@/store/enums/StoreEnums";
import router from "@/router";
export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  setup() {

    const store = useStore();
    const user = computed(() => {
      return store.getters.currentUser;
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      user,
      router,
    };
  },
});
