
import { defineComponent, ref,watch } from "vue";
import { useI18n } from "vue-i18n/index";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const { t } = useI18n();
    const isToggleActive = ref(false);

    const toggleAside = () => {
      isToggleActive.value = !isToggleActive.value;
    };
    watch(isToggleActive, (newValue) => {
    });
    return {
      asideTheme,
      t,
      toggleAside,
      isToggleActive
    };
  },
});
